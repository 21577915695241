import { render, staticRenderFns } from "./Remark.vue?vue&type=template&id=ccfdcbea&scoped=true"
import script from "./Remark.vue?vue&type=script&lang=js"
export * from "./Remark.vue?vue&type=script&lang=js"
import style0 from "./Remark.vue?vue&type=style&index=0&id=ccfdcbea&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccfdcbea",
  null
  
)

export default component.exports